<template>
    <b-container>
        <b-row class="bg-sukudai-green py-5">
            <b-col cols="12" class="text-center text-white">
                <p class="font-xxl font-weight-bolder mb-2">感謝您的詢問</p>
                <p class="font-lg font-weight-bold">速可貸專員將盡快與您聯絡，謝謝!!</p>
                <b-button variant="warning" size="md" class="text-white"
                          @click="$router.push({'name': 'Home'})">
                    回首頁
                </b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: "thanks"
}
</script>

<style scoped>

</style>